import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const UserTypeKey = 'User-Type-key'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserType() {
  return Cookies.get(UserTypeKey)
}

export function setUserType(loginType) {
  return Cookies.set(UserTypeKey, loginType)
}

export function removeUserType() {
  return Cookies.remove(UserTypeKey)
}

