// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  if (time.indexOf('01-01-01') > -1) {
    return '-'
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// 表单重置
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields()
  }
}

// 添加日期范围
export function addDateRange(params, dateRange) {
  var search = params
  search.beginTime = ''
  search.endTime = ''
  if (dateRange != null && dateRange !== '') {
    search.beginTime = this.dateRange[0]
    search.endTime = this.dateRange[1]
  }
  return search
}

// 回显数据字典
export function selectDictLabel(datas, value) {
  var actions = []
  Object.keys(datas).map((key) => {
    if (datas[key].value === ('' + value)) {
      actions.push(datas[key].label)
      return false
    }
  })
  return actions.join('')
}

export function selectItemsLabel(datas, value) {
  var actions = []
  Object.keys(datas).map((key) => {
    // if (datas[key].key === ('' + value)) {
    if (datas[key].key === (value)) {
      actions.push(datas[key].value)
      return false
    }
  })
  return actions.join('')
}

// 字符串格式化(%s )
export function sprintf(str) {
  var args = arguments
  var flag = true
  var i = 1
  str = str.replace(/%s/g, function() {
    var arg = args[i++]
    if (typeof arg === 'undefined') {
      flag = false
      return ''
    }
    return arg
  })
  return flag ? str : ''
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return ''
  }
  return str
}

// 关闭tab wxp
export function close(who, path) {
  who.$store.dispatch('tagsView/delView', this.$route)
  who.$router.push({ path: path, query: { t: Date.now() }})
}

/**
 * 获取身份证的信息
 * @param card 身份证
 * @param num（1：出生日期,2:性别,3:年龄）
 * @returns {string|number}
 */
export function getIdCardInfo(card, num) {
  if (num === 1) {
    // 获取出生日期
    return card.substring(6, 10) + '-' + card.substring(10, 12) + '-' + card.substring(12, 14)
  }
  if (num === 2) {
    // 获取性别
    if (parseInt(card.substr(16, 1)) % 2 === 1) {
      // 男
      return '男'
    } else {
      // 女
      return '女'
    }
  }
  if (num === 3) {
    // 获取年龄
    var myDate = new Date()
    var month = myDate.getMonth() + 1
    var day = myDate.getDate()
    var age = myDate.getFullYear() - card.substring(6, 10) - 1
    if (card.substring(10, 12) < month || card.substring(10, 12) === month && card.substring(12, 14) <= day) {
      age++
    }
    return age
  }
  if (num === 4) {
    // 获取性别
    if (parseInt(card.substr(16, 1)) % 2 === 1) {
      // 男
      return '1'
    } else {
      // 女
      return '2'
    }
  }
}

// 身份证校验函数
export function IdentityCodeValid(rule, value, callback) {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  const reg = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
  if (!value) {
    return callback(new Error('身份证号不能为空'))
  } else if (!reg.test(value)) {
    callback(new Error('你输入的身份证长度或格式错误'))
  } else {
    // 身份证城市
    var aCity = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外'
    }
    if (!aCity[parseInt(value.substr(0, 2))]) {
      callback(new Error('你的身份证地区非法'))
    }
    // 出生日期验证
    var sBirthday = (
      value.substr(6, 4) + '-' +
      Number(value.substr(10, 2)) + '-' +
      Number(value.substr(12, 2))
    ).replace(/-/g, '/')
    var d = new Date(sBirthday)
    if (sBirthday !== d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate()) {
      callback(new Error('身份证上的出生日期非法'))
    }

    // 身份证号码校验
    var sum = 0
    var weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    var codes = '10X98765432'
    for (var i = 0; i < value.length - 1; i++) {
      sum += value[i] * weights[i]
    }
    var last = codes[sum % 11] // 计算出来的最后一位身份证号码
    if (value[value.length - 1] !== last) {
      callback(new Error('你输入的身份证号非法'))
    }
    callback()
  }
}
