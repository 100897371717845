import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css
import '@/styles/admin.scss'

import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'

import { getDicts } from '@/api/admin/dict/data'
import { getItems, setItems } from '@/api/table'
import { getConfigKey } from '@/api/admin/sys-config'
import {
  addDateRange,
  close,
  parseTime,
  resetForm,
  selectDictLabel,
  selectItemsLabel,
  getIdCardInfo,
  IdentityCodeValid
} from '@/utils/costum'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import Viser from 'viser-vue'
import * as filters from './filters' // global filters
import Pagination from '@/components/Pagination'
import BasicLayout from '@/layout/BasicLayout'

import VueParticles from 'vue-particles'
import '@/utils/dialog'
import VueDND from 'awe-dnd'
import 'remixicon/fonts/remixicon.css'
// 图片裁剪工具
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
// // 打印插件
// import Print from '@/plugs/print'
// Vue.use(Print)

Vue.use(VueCodemirror)

Vue.use(Viser)

Vue.use(VueParticles)

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getItems = getItems
Vue.prototype.setItems = setItems
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectItemsLabel = selectItemsLabel
Vue.prototype.getIdCardInfo = getIdCardInfo
Vue.prototype.IdentityCodeValid = IdentityCodeValid
// Vue.prototype.download = download
Vue.prototype.closeTab = close

// import VueMqtt from 'vue-mqtt'
// const broker = 'ws://121.36.10.113:8083/mqtt'
// Vue.use(VueMqtt, broker)
// Vue.prototype.$mqtt = {
//   connect(host, port, clientId) {
//     this.$mqtt.connect(`tcp://${host}:${port}`, { clientId })
//   },
//   subscribe(topic, qos) {
//     this.$mqtt.subscribe(topic, qos)
//   },
//   unsubscribe(topic) {
//     this.$mqtt.unsubscribe(topic)
//   },
//   publish(topic, message, qos, retain) {
//     this.$mqtt.publish(topic, message, { qos, retain })
//   },
//   disconnect() {
//     this.$mqtt.disconnect()
//   }
// }
// const clientId = new Date().getMilliseconds()
// Vue.prototype.$mqtt.connect('121.36.10.113', 1883, clientId)

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('BasicLayout', BasicLayout)

Vue.prototype.msgSuccess = function(msg) {
  this.$message({ showClose: true, message: msg, type: 'success' })
}

Vue.prototype.msgError = function(msg) {
  this.$message({ showClose: true, message: msg, type: 'error' })
}

Vue.prototype.msgInfo = function(msg) {
  this.$message.info(msg)
}

Vue.use(permission)

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.use(VueDND)

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
